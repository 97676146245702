export const NOISE_LINKS = [
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/city_traffic.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/rain_city.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/fireplace.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/campfire.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/forest_night.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/rain_forest.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/waves.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/fan.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/summer_storm.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/river.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/birds.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/people_talk_inside.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/wind.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/ocean.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/snow.mp3',
	'https://s3.us-east-2.amazonaws.com/lofi.co/lofi.co/effects/keyboard.mp3',
];