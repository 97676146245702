import {
	setChillImg,
	setSpringImg,
	setBookImg,
	setNorthernImg,
	setDeskImg,
	setForestImg,
	setOceanImg,
	setCafeImg,
	setVanImg,
	setSummerImg,
	sceneChill1Img,
	sceneChill2Img,
	sceneNorthern1Img,
	sceneNorthern2Img,
	sceneDesk1Img,
	sceneDesk2Img,
	sceneDesk3Img,
	sceneForest1Img,
	sceneForest2Img,
	sceneOcean1Img,
	sceneOcean2Img,
	sceneCafe1Img,
	sceneCafe2Img,
	sceneVan1Img,
	sceneVan2Img,
	sceneSummer1Img,
	sceneSummer2Img,
	sceneSpring1Img,
	sceneSpring2Img,
	sceneBook1Img,
	sceneBook2Img,
} from '../assets/images';

export const SETS = [
	{
		set: 'chill',
		img: setChillImg,
		scenes: [
			{
				scene: 'scene1',
				img: sceneChill1Img,
			},
			{
				scene: 'scene2',
				img: sceneChill2Img,
			},
		],
	},
	{
		set: 'spring',
		img: setSpringImg,
		scenes: [
			{
				scene: 'scene1',
				img: sceneSpring1Img,
			},
			{
				scene: 'scene2',
				img: sceneSpring2Img,
			},
		],
	},
	{
		set: 'northern',
		img: setNorthernImg,
		scenes: [
			{
				scene: 'scene1',
				img: sceneNorthern1Img,
			},
			{
				scene: 'scene2',
				img: sceneNorthern2Img,
			},
		],
	},
	{
		set: 'book',
		img: setBookImg,
		scenes: [
			{
				scene: 'scene1',
				img: sceneBook1Img,
			},
			{
				scene: 'scene2',
				img: sceneBook2Img,
			},
		],
	},
	{
		set: 'desk',
		img: setDeskImg,
		scenes: [
			{
				scene: 'scene1',
				img: sceneDesk1Img,
	
			},
			{
				scene: 'scene2',
				img: sceneDesk2Img,
	
			},
			{
				scene: 'scene3',
				img: sceneDesk3Img,
	
			},
		],
	},
	{
		set: 'forest',
		img: setForestImg,
		scenes: [
			{
				scene: 'scene1',
				img: sceneForest1Img,
	
			},
			{
				scene: 'scene2',
				img: sceneForest2Img,
	
			},
		],
	},
	{
		set: 'ocean',
		img: setOceanImg,
		scenes: [
			{
				scene: 'scene1',
				img: sceneOcean1Img,
	
			},
			{
				scene: 'scene2',
				img: sceneOcean2Img,
	
			},
		],
	},
	{
		set: 'cafe',
		img: setCafeImg,
		scenes: [
			{
				scene: 'scene1',
				img: sceneCafe1Img,
	
			},
			{
				scene: 'scene2',
				img: sceneCafe2Img,
	
			},
		],
	},
	{
		set: 'van',
		img: setVanImg,
		scenes: [
			{
				scene: 'scene1',
				img: sceneVan1Img,
	
			},
			{
				scene: 'scene2',
				img: sceneVan2Img,
	
			},
		],
	},
	{
		set: 'summer',
		img: setSummerImg,
		scenes: [
			{
				scene: 'scene1',
				img: sceneSummer1Img,
	
			},
			{
				scene: 'scene2',
				img: sceneSummer2Img,
	
			},
		],
	},
];